import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { Link, graphql } from "gatsby";
import Text from "../components/Theme";
// import { Link } from "gatsby";
import Layout from "../components/layout";
import ResponsiveEmbed from "react-responsive-embed";
import Footer from "../components/footer.js";
import Header from "../components/Header";
import media from "styled-media-query";
import ReactCompareImage from "react-compare-image";
import Plx from "react-plx";
import { HelmetDatoCms } from "gatsby-source-datocms";

// import Carousel from "nuka-carousel";

// const CarouselFrame = styled(Carousel)`
//     width: 100%;
//     li {
//         cursor: default;
//     }
//     .slider-frame {
//         width: 100%;
//     }
// `;

const ParallaxContainer = styled.div`
    overflow: hidden;
`;

const ImgCaption = styled(Text)`
    text-align: center;
    font-size: 11px;
    margin-top: 20px;
    padding: 0px 200px 80px 200px;
    opacity: 0.5;
    line-height: 18px;
`;
const PageHeader = styled.h1`
    grid-column: 2/8;
    padding: 40px 0 0 0;
    font-weight: 100;
    font-size: 52px;
    line-height: 79px;

    ${media.lessThan("large")`
        grid-column: 2/12;
    
    `}
    ${media.lessThan("medium")`
        grid-column: 1/13;

        font-size: 36px;
        line-height: 48px;
    `}
`;

const Subhead = styled(Text)`
    padding: 20px 0 60px 0;
    ${media.lessThan("medium")`
        
    `}
`;

const MoreHeader = styled(Text)`
    grid-column: 4/10;
    opacity: .4;
    text-align: center;
    padding: 80px 0 80px 0;
    margin-top: 40px;
    border-top: 1px solid rgba(0,0,0,.1);
    
    
/*     
    ${media.lessThan("large")`
        padding-bottom: 20px;
        font-size: 36px;
        line-height: 48px;
    `} */
`;
const scale = [
    {
        start: 0,
        end: 1200,
        properties: [
            {
                startValue: 1,
                endValue: 2,
                property: "scale"
            }
        ]
    }
];

const transY = [
    {
        start: "self",
        end: "100%",
        properties: [
            {
                startValue: 100,
                endValue: 0,
                property: "translateY"
            }
        ]
    }
];
const RelatedStory = styled.div`
    grid-column: span 6;
    margin-bottom: 60px;
    &:nth-child(1) {
        grid-column: 3/7;
    }
    &:nth-child(2) {
        grid-column: 7/11;
    }
    ${media.lessThan("medium")`
        margin-bottom: 20px;
        &:nth-child(1) {
            grid-column: 1/13;
        }
        &:nth-child(2) {
            grid-column: 1/13;
        }
    `}
`;

export default ({ data }) => (
    <Layout>
        <HelmetDatoCms>
            <title>{data.datoCmsStory.title}</title>
            <meta property="og:title" content={data.datoCmsStory.title} />
            <meta
                property="og:description"
                content={data.datoCmsStory.subtitle}
            />
            <meta
                property="og:image"
                content={data.datoCmsStory.featuredImage.url}
            />
        </HelmetDatoCms>
        <Header content_color="black" />

        <div className="story-template">
            <div className="main-grid story-grid">
                <PageHeader>{data.datoCmsStory.title}</PageHeader>
                <Subhead body col="2/12">
                    {data.datoCmsStory.subtitle}
                </Subhead>
                <div className="story-featured-img">
                    <ParallaxContainer>
                        <Plx parallaxData={scale}>
                            <Img
                                fluid={data.datoCmsStory.featuredImage.fluid}
                                alt={data.datoCmsStory.title}
                            />
                        </Plx>
                    </ParallaxContainer>
                    {data.datoCmsStory.featuredImage.title && (
                        <ImgCaption small>
                            {data.datoCmsStory.featuredImage.title}
                        </ImgCaption>
                    )}
                </div>
            </div>
        </div>

        <div className="main-grid story-grid">
            {data.datoCmsStory.content.map((block) => (
                <div className={`block ${block.model.apiKey}`} key={block.id}>
                    {block.model.apiKey === "text_block" && (
                        <div
                            className="body-copy-medium"
                            dangerouslySetInnerHTML={{ __html: block.text }}
                        ></div>
                    )}

                    {block.model.apiKey === "video_embed" && (
                        <div className={"content-video"}>
                            <div className={"content-video__wrapper"}>
                                {block.url.provider === "youtube" ? (
                                    <ResponsiveEmbed
                                        src={`//www.youtube.com/embed/${block.url.providerUid}`}
                                        ratio={`${block.url.width}:${block.url.height}`}
                                        allowFullScreen
                                    />
                                ) : (
                                    <ResponsiveEmbed
                                        src={`//player.vimeo.com/video/${block.url.providerUid}?title=0&byline=0&portrait=0`}
                                        ratio={`${block.url.width}:${block.url.height}`}
                                        allowFullScreen
                                    />
                                )}
                                <ImgCaption small>{block.caption}</ImgCaption>
                            </div>
                        </div>
                    )}

                    {block.model.apiKey === "media_one_column" && (
                        <>
                            <Img
                                fluid={block.image.fluid}
                                alt={data.datoCmsStory.title}
                            />

                            {block.image.title && (
                                <ImgCaption small>
                                    {block.image.title}
                                </ImgCaption>
                            )}
                        </>
                    )}
                    {block.model.apiKey === "media_two_column" && (
                        <div>
                            {block.imageRowBlock.map((img) => (
                                <Img
                                    fluid={img.fluid}
                                    alt={data.datoCmsStory.title}
                                />
                            ))}
                        </div>
                    )}
                    {block.model.apiKey === "image_comparison_block" && (
                        <>
                            <ReactCompareImage
                                leftImage={block.oldImage.url}
                                rightImage={block.newImage.url}
                            />
                            <ImgCaption small>{block.caption}</ImgCaption>
                        </>
                    )}
                </div>
            ))}
        </div>
        <div className="main-grid story-grid">
            <MoreHeader caption>Read More</MoreHeader>
        </div>
        <div className="main-grid story-grid small-grid">
            {data.datoCmsStory.relatedStories.map((block) => (
                <RelatedStory className="card">
                    <Link to={`/stories/${block.slug}`}>
                        <Img fluid={block.featuredImage.fluid} />
                        <div className="partnership-text">
                            <Text
                                caption
                                style={{
                                    opacity: 0.5,
                                    margin: "10px 0 0 0",
                                    fontSize: "11px",
                                }}
                            ></Text>
                            <Text sub style={{ margin: 0 }}>
                                {block.title}
                            </Text>
                            <Text
                                body
                                style={{
                                    opacity: 0.5,
                                    fontSize: "18px",
                                    lineHeight: "24px",
                                }}
                            >
                                {block.subtitle}
                            </Text>
                        </div>
                    </Link>
                </RelatedStory>
            ))}
        </div>
        <Footer />
    </Layout>
);

export const query = graphql`
           query singleStoryQuery($slug: String!) {
               datoCmsStory(slug: { eq: $slug }) {
                   relatedStories {
                       featuredImage {
                        
                           fluid(imgixParams: {
                                       h: "400"
                                       w: "600"
                                       fit: "crop"
                                   }) {


                               base64
                               aspectRatio
                               width
                               height
                               src
                               srcSet
                               sizes
                           }
                       }
                       title
                       subtitle
                       slug
                   }

                   id
                   title
                   subtitle
                   slug
                   category {
                       name
                   }
                   featuredImage {
                       url
                       fluid(imgixParams: { fm: "jpg" }) {
                           base64
                           aspectRatio
                           width
                           height
                           src
                           srcSet
                           sizes
                       }
                       title
                   }
                   content {
                       ... on DatoCmsTextBlock {
                           model {
                               apiKey
                           }
                           text
                       }
                       ... on DatoCmsMediaOneColumn {
                           model {
                               apiKey
                           }
                           image {
                               fluid(
                                   maxWidth: 1600
                                   imgixParams: {
                                       dpr: 1
                                       w: "1600"
                                       fit: "clip"
                                       auto: "compress"
                                   }
                               ) {
                                   ...GatsbyDatoCmsFluid
                               }
                               title
                           }
                       }
                       ... on DatoCmsMediaTwoColumn {
                           model {
                               apiKey
                           }

                           imageRowBlock {
                               fluid(
                                   maxWidth: 1600
                                   imgixParams: {
                                       dpr: 1
                                       w: "1600"
                                       fit: "clip"
                                       auto: "compress"
                                   }
                               ) {
                                   ...GatsbyDatoCmsFluid
                               }
                               title
                           }
                       }
                       ... on DatoCmsImageComparisonBlock {
                           model {
                               apiKey
                           }
                           newImage {
                               url
                           }
                           oldImage {
                               url
                           }
                           caption
                       }
                       ... on DatoCmsVideoEmbed {
                           model {
                               apiKey
                           }
                           url {
                               url
                               title
                               provider
                               providerUid
                               thumbnailUrl
                               width
                               height
                           }
                           caption
                       }
                   }
               }
           }
       `;
